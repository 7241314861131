import React, {useEffect, useRef, useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import booksStyle from "./books.module.css"
import Carousel from "react-multi-carousel";
import Img from "gatsby-image";
import BooksPortraitCarousel from "../components/books-carousel";
import formStyle from "./form.module.css";
import axios from "axios";
import env from "../config/env";
import addToMailchimp from "gatsby-plugin-mailchimp";

const BooksPage = ({data, location}) => {

  const [selectedBookImg, setSelectedBookImg] = useState('book-1');
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [showModalError, setShowModalErrorAlert] = useState({show: false, onField: ""});
  const [modalShow, setModalShow] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [showBookForm, setShowBookForm] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const [formState, setFormValues] = useState({
    book:"",
    bookTitle: "",
    bookAuthorName: "",
    bookDedication: "",
    bookDesign: "",
    pageOneText: "",
    pageTwoText: "",
    pageThreeText: "",
    pageFourText: "",
    pageFiveText: "",
    pageSixText: "",
    pageSevenText: "",
    pageEightText: "",
    pageNineText: "",
    pageTenText: "",
    firstName: "",
    lastName: "",
    address: "",
    phone: "",
    email: "",
    writingStyle: "Common font",
    deliveryType: "Regular Delivery",
    noOfCopies: 1,
    aboutBook: "",
    fileB64: "",
    fileName: "",
    fileType: "",
    fileUploaded: "",
  });

  const books = [
    {
      id: 'book-1',
      title: 'Book1',
      images: [
        'book-1',
      ],
    },
    {
      id: 'book-2',
      title: 'Book2',
      images: [
        'book-2',
      ],
    },
    {
      id: 'book-3',
      title: 'Book3',
      images: [
        'book-3',
      ],
    },
    {
      id: 'book-4',
      title: 'Book4',
      images: [
        'book-4',
      ],
    },
    {
      id: 'book-5',
      title: 'Book5',
      images: [
        'book-5',
      ],
    },
  ];

  const booksCarousel = useRef(null);

  const updateCarouselImage = (imageName) => {
    const index = data.booksCarousel.edges.findIndex(edge => edge.node.name === imageName)
    if (booksCarousel.current) {
      booksCarousel.current.goToSlide(index);
    }
  }

  const changeBookSlide = (image) => {
    setSelectedBookImg(image);
    updateCarouselImage(image);
  }

  const isBookSelected = (imageName) => {
    const book = books.find(item => item.images.indexOf(imageName) > -1);
  }

  const validateForm = () => {
    const requiredFields = ["bookTitle", "bookAuthorName", "bookDedication", "pageOneText", "pageTwoText",
      "pageThreeText", "pageFourText", "pageFiveText", "pageSixText", "pageSevenText", "pageEightText"
    ];

    for (const field in formState) {
      if(requiredFields.indexOf(field) !== -1) {
        if(!formState[field]) {
          setShowErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase() + field.slice(1)
          });
          return false;
        }
      }
    }

    return true;
  };

  const validateModalForm = () => {
    const requiredFields = ["firstName", "lastName", "email", "phone", "address", "aboutBook"];
    for (const field in formState) {
      if(requiredFields.indexOf(field) !== -1) {
        if(!formState[field]) {
          setShowModalErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase() + field.slice(1)
          });
          return false;
        }
      }
    }

    return true;
  };

  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => {
    // if(!formState.fileName) {
    //   let isValid = validateForm();
    //   if(isValid) {
    //     setModalShow(true);
    //   }
    // } else {
      setModalShow(true);
    // }
  }

  const isFileUploaded = () => {
    if(formState.fileName) {
      formState.fileUploaded = "YES"
    } else {
      formState.fileUploaded = "NO"
    }

  }

  const saveToMailchimp = async () => {
    isFileUploaded();
    try {
      const result = await addToMailchimp(formState.email, {
        BOOKTITLE: formState.bookTitle,
        AUTHORNAME: formState.bookAuthorName,
        DEDICATION: formState.bookDedication,
        DESIGN: formState.bookDesign,
        PAGE1: formState.pageOneText,
        PAGE2: formState.pageTwoText,
        PAGE3: formState.pageThreeText,
        PAGE4: formState.pageFourText,
        PAGE5: formState.pageFiveText,
        PAGE6: formState.pageSixText,
        PAGE7: formState.pageSevenText,
        PAGE8: formState.pageEightText,
        PAGE9: formState.pageNineText,
        FIRSTNAME: formState.firstName,
        LASTNAME: formState.lastName,
        PHONE: formState.phone,
        EMAIL: formState.email,
        ADDRESS: formState.address,
        WRITING: formState.writingStyle,
        DLVTYPE: formState.deliveryType,
        NOOFCOPIES: formState.noOfCopies,
        ABOUT: formState.aboutBook,
        FILE: formState.fileUploaded
        }, env.submitBookUrl
      );

      if (result.result === 'error') {
        setShowAlert({
          show: true,
          msg: 'Something went wrong! Please try again later!'
        });
      } else {
        setFormValues(result);
        setShowAlert({
          show: true,
          msg: result.msg
        });
        handleCloseModal();
    }
    } catch (err) {
      console.error(err);
    }
    return true;
  };

  useEffect(() => {
    updateFile();
  }, [formState]);

  const updateFile = async () => {
    if(!formState.fileName) {
      setShowFile(false);
      setShowBookForm(true);
    } else {
      setShowFile(true);
      setShowBookForm(false);
    }
  }

  const removeFile = () => {
    if(formState.fileName) {
      setFormValues({...formState, fileName: ""})
      setShowFile(false);
    }
  }

  const handleFile = (formChangeEvent) => {
    const file = formChangeEvent.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      setFormValues({
        ...formState,
        fileName: file.name,
        fileB64: btoa(readerEvent.target.result),
        fileType: file.type,
      });
    };
    reader.readAsBinaryString(file);
  };

  const saveBookToS3 = async () => {
    return axios({
      method: 'post',
      url: env.uploadBookUrl,
      data: {
        email: formState.email,
        fileB64: formState.fileB64,
        fileType: formState.fileType
      },
      headers: {
        'Access-Control-Allow-Origin': window.location.origin,
      },
    });
  };

  const validateAndSaveData = async () => {
    let isValid = validateModalForm();
    if (isValid) {
      await saveToMailchimp();
      if(formState.fileName) {
        await saveBookToS3();
      }
    }
  };

  const handleBookFormChange = e => {

    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "photo") {
      updateFile();
      handleFile(e);
      e.target.value = null;
    }
  };


  return (
    <Layout>
      <Container fluid className={booksStyle.portraitStyle}>
        <Row>
          <Col className={booksStyle.largeTitle}>
            <h1 className={globalStyle.entryTitle}>MAKE A CHILDREN'S <span>BOOK</span></h1>
          </Col>
        </Row>
        <Row className={"justify-content-center " + booksStyle.content}>
          <Col xs={12} sm={12} md={11} lg={10} xl={9}>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Row>
                  <Col>
                    { data.booksCarousel.edges.map(edge => {
                      return (
                        <div key={edge.node.name} className={selectedBookImg === edge.node.name ? '' : 'd-none'}>
                          <BooksPortraitCarousel
                            photo={edge.node.childImageSharp.fluid}
                          >
                          </BooksPortraitCarousel>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
                <Row style={{"paddingLeft": "0px"}}>
                  <Col>
                    <Carousel
                      ref={booksCarousel}
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={3000}
                      autoPlay={false}
                      centerMode={false}
                      className={booksStyle.smallCarousel}
                      containerClass="container-with-dots"
                      dotListClass=""
                      focusOnSelect={false}
                      itemClass=""
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 4,
                          partialVisibilityGutter: 40
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        }
                      }}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      infinite={false}
                    >
                      {data.booksCarousel.edges.map((edge, item) => (
                        <div
                          key={edge.node.name}
                          onClick={() => changeBookSlide(edge.node.name)}
                          className={isBookSelected(edge.node.name) ? 'selected' : 'notSelected'}
                        ><Img fluid={edge.node.childImageSharp.fluid} /></div>
                      ))}
                    </Carousel>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={6}>
                <Row>
                  <Col>
                    <h2 className={booksStyle.choosePortretType}>Turn Your Child's Story into a
                      Real Book</h2>
                    <h3 className={booksStyle.sectionTitle}>How It Works?</h3>
                    <h6 className={booksStyle.sectionTitle}>Step 01:</h6>
                    <p>Help your child write a short story or write one yourself! Use our template or upload your
                      draft. (<a href="#requestAQuote" className={booksStyle.redirectLink}>click to see template</a>)
                    </p>
                    <h6 className={booksStyle.sectionTitle}>Step 02:</h6>
                    <p>Share your story with us here at Moms on Maternity Book Services.</p>
                    <h6 className={booksStyle.sectionTitle}>Step 03:</h6>
                    <p>Our animators will bring your story to life and make it into a “real book.”</p>
                    <h6 className={booksStyle.sectionTitle}>Step 04:</h6>
                    <p>Once you approve your book, we print and ship it to you and yours!</p>

                    <Row className="justify-content-md-center">
                      <Col className="text-left">
                        <Button
                          type="button"
                          className={[
                            globalStyle.btnPrimary,
                            booksStyle.confirmButton
                          ]}
                          onClick={handleShowModal}
                        ><a className={booksStyle.requestAQuoteButton}>Request A Quote For Your Book Creation</a>
                        </Button>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className={booksStyle.processLayout}>
              <Col>
                <Img fluid={data.booksLayout.childImageSharp.fluid} />
              </Col>
            </Row>

            <Row id="requestAQuote">
              <Col>
                <h3 className={booksStyle.sectionTitle}></h3>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className={booksStyle.sectionTitle}>Upload Your Book Draft Now</h3>
              </Col>
            </Row>

            <Row className={booksStyle.uploadSection}>
              <Col md={7} sm={7} xs={12}>
                <Row>
                  <Col xs={12} md={4} sm={12}>
                    <Form.Group className={booksStyle.formGroup}>
                      <Form.File
                        id="custom-file-translate-scss"
                        label={"Upload Now"}
                        lang="en"
                        custom
                        className={booksStyle.uploadButton}
                        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/doc,image/jpeg,image/jpg,image/png"
                        name="photo"
                        onChange={handleBookFormChange}
                      />
                    </Form.Group>

                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    {showBookForm &&
                      <Img fluid={data.orLine.childImageSharp.fluid} />
                    }
                  </Col>
                </Row>

                {showFile &&
                  <Form.Row className={booksStyle.fileNameContent}>
                    <Col>
                      <p>{formState.fileName}
                        <span>
                          <i className={[booksStyle.closeIcon, "far fa-times-circle"].join(' ')}
                             onClick={removeFile}></i>
                        </span>
                      </p>
                    </Col>
                  </Form.Row>
                }

              </Col>
              <Col md={5} sm={5} xs={12}></Col>
            </Row>

            {showBookForm &&
              <Row>
                <Col>
                  <h3 className={booksStyle.sectionTitle}>Let Us Know What You Want On Each Page</h3>
                </Col>
              </Row>
            }
            {showBookForm &&
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                  <Form.Group as={Col} controlId="bookTitle" name="bookTitle"
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required]}>Book Title:</Form.Label>
                    <Form.Control
                      className={booksStyle.formInput}
                      size="sm"
                      value={formState.bookTitle}
                      type="text"
                      name="bookTitle"
                      placeholder="How To Play Footaball"
                      onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                  <Form.Group as={Col} controlId="bookAuthorName" name="bookAuthorName"
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Book Author's
                      Name:</Form.Label>
                    <Form.Control
                      className={booksStyle.formInput}
                      size="sm"
                      value={formState.bookAuthorName}
                      type="text"
                      name="bookAuthorName"
                      placeholder="R.S. Cruz"
                      onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                  <Form.Group as={Col} controlId="bookDedication" name="bookDedication"
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>
                      Book Dedication:
                    </Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaLargeStyle].join(' ')}
                                  value={formState.bookDedication}
                                  type="text"
                                  name="bookDedication" placeholder="To Mom and Dad..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="bookDesign" name="bookDesign" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel].join(' ')}>Are there
                      any special designs you would like to see or does our illustrator have
                      full creative license to bring your words to life in the book’s drawings?</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaLargeStyle].join(' ')}
                                  value={formState.bookDesign}
                                  type="text"
                                  name="bookDesign" placeholder="If yes, write here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Col>

              <Col xs={12} sm={12} md={6}>
                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageOneText" name="pageOneText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label
                      className={[formStyle.formLabel, formStyle.required, booksStyle.pageOneLabel].join(' ')}>Page 1
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageOneText}
                                  type="text"
                                  name="pageOneText" placeholder="Page 1 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageTwoText" name="pageTwoText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 2
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageTwoText}
                                  type="text"
                                  name="pageTwoText" placeholder="Page 2 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageOneThreeContent" name="pageOneThreeContent" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 3
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageThreeText}
                                  type="text"
                                  name="pageThreeText" placeholder="Page 3 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageFourText" name="pageFourText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 4
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageFourText}
                                  type="text"
                                  name="pageFourText" placeholder="Page 4 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageFiveText" name="pageFiveText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 5
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageFiveText}
                                  type="text"
                                  name="pageFiveText" placeholder="Page 5 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageSixText" name="pageSixText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 6
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageSixText}
                                  type="text"
                                  name="pageSixText" placeholder="Page 6 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageSevenText" name="pageSevenText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 7
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageSevenText}
                                  type="text"
                                  name="pageSevenText" placeholder="Page 7 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageEightText" name="pageEightText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel, formStyle.required].join(' ')}>Page 8
                      text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageEightText}
                                  type="text"
                                  name="pageEightText" placeholder="Page 8 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageNineText" name="pageNineText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel].join(' ')}>Page 9 text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageNineText}
                                  type="text"
                                  name="pageNineText" placeholder="Page 9 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className={["justify-content-md-center"].join(" ")}>
                  <Form.Group controlId="pageTenText" name="pageTenText" as={Col}
                              className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                    <Form.Label className={[formStyle.formLabel].join(' ')}>Page 10 text:</Form.Label>
                    <Form.Control size="sm" as="textarea"
                                  className={[booksStyle.textareaSmallStyle].join(' ')}
                                  value={formState.pageTenText}
                                  type="text"
                                  name="pageTenText" placeholder="Page 10 text here..."
                                  onChange={handleBookFormChange}
                    />
                  </Form.Group>
                </Form.Row>

              </Col>
            </Row>
            }

            <Row>
              <Col md={6}>
                {
                  showError.show &&
                  <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                  >{showError.onField} field should not be empty</Alert>
                }
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col className="text-left" >
                <Button
                  type="button"
                  className={[
                    globalStyle.btnPrimary,
                    booksStyle.confirmButton
                  ]}
                  onClick={handleShowModal}
                >Request A Quote For Your Book Creation
                </Button>
              </Col>
              <Col md={6}></Col>
            </Row>

            <Form.Row>
              <Col>
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={modalShow}
                  onHide={handleCloseModal}
                  className="addBookDetailsModal"
                >
                  <Modal.Header className={[booksStyle.modalBackground, booksStyle.modalHeader].join(' ')}
                                closeButton>
                    <Form.Row>
                      <Col>
                        <h3 className={booksStyle.modalTitle}>Quote For Book Production</h3>
                      </Col>
                    </Form.Row>

                  </Modal.Header>
                  <Modal.Body className={booksStyle.modalBackground}>
                    <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group as={Col} className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]} htmlFor="firstName">First name</Form.Label>
                          <Form.Control
                            size="lg"
                            value={formState.firstName}
                            name="firstName"
                            type="text"
                            placeholder="Type your first name"
                            onChange={handleBookFormChange}
                            className={booksStyle.formInput}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group as={Col} className={[booksStyle.registerFormGroup, booksStyle.formLabel].join(' ')}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]} htmlFor="lastName">Last name</Form.Label>
                          <Form.Control
                            size="lg"
                            value={formState.lastName}
                            name="lastName"
                            type="text"
                            placeholder="Type your last name"
                            onChange={handleBookFormChange}
                            className={booksStyle.formInput}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                      <Col xs={12} sm={12} md={6} >
                        <Form.Group as={Col} className={[booksStyle.registerFormGroup, booksStyle.formLabel]}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]} htmlFor="email">Email address</Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.email}
                            name="email"
                            type="email"
                            placeholder="Type your email address"
                            onChange={handleBookFormChange}
                            className={booksStyle.formInput}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12} sm={12} md={6} >
                        <Form.Group as={Col} className={[booksStyle.registerFormGroup, booksStyle.formLabel]}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]} htmlFor="phone">Phone no</Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.phone}
                            name="phone"
                            type="text"
                            placeholder="Type your phone no."
                            onChange={handleBookFormChange}
                            className={booksStyle.formInput}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                      <Col xs={12} sm={12} md={6} >
                        <Form.Group as={Col} className={[booksStyle.registerFormGroup, booksStyle.formLabel]}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]} htmlFor="address">Shipping address</Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.address}
                            name="address"
                            type="text"
                            placeholder="Type your address"
                            onChange={handleBookFormChange}
                            className={[booksStyle.formInput, booksStyle.addressInput].join(' ')}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6} className={booksStyle.alignmentInputStyle}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]} >Choose your writing style:</Form.Label>

                        <Form.Check>
                          <Form.Check.Input type='radio' id="common-font"
                                            name="writingStyle"
                                            value="Common font"
                                            checked={formState.writingStyle === "Common font"}
                                            onChange={handleBookFormChange}/>
                          <Form.Check.Label htmlFor="common-font">Common type font</Form.Check.Label>
                        </Form.Check>
                        <Form.Check>
                          <Form.Check.Input type='radio' id="natural-handwriting"
                                            name="writingStyle"
                                            value="Natural handwriting"
                                            checked={formState.writingStyle === "Natural handwriting"}
                                            onChange={handleBookFormChange}/>
                          <Form.Check.Label htmlFor="natural-handwriting">Natural handwriting</Form.Check.Label>
                        </Form.Check>
                        <Form.Check>
                          <Form.Check.Input type='radio' id="hand-penned"
                                            name="writingStyle"
                                            value="Hand penned"
                                            checked={formState.writingStyle === "Hand penned"}
                                            onChange={handleBookFormChange}/>
                          <Form.Check.Label htmlFor="hand-penned">Hand penned font by the artist</Form.Check.Label>
                        </Form.Check>
                      </Col>
                    </Form.Row>

                    <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                      <Col xs={12} sm={12} md={6} className={booksStyle.alignmentInputStyle}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Number of copies:</Form.Label>
                        <Form.Group as={Col} controlId="noOfCopies"
                                    className={[booksStyle.registerFormGroup, booksStyle.formLabel]}>
                          <Form.Control
                            size="sm"
                            value={formState.noOfCopies}
                            name="noOfCopies"
                            type="number"
                            min="1"
                            onChange={handleBookFormChange}
                            className={booksStyle.formInput}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6} className={booksStyle.alignmentInputStyle}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Choose your delivery:</Form.Label>
                        <Form.Check>
                          <Form.Check.Input type='radio' id="regular-delivery"
                                            name="deliveryType"
                                            value="Regular Delivery"
                                            checked={formState.deliveryType === "Regular Delivery"}
                                            onChange={handleBookFormChange}/>
                          <Form.Check.Label htmlFor="regular-delivery">Regular 30-45 days delivery.</Form.Check.Label>
                        </Form.Check>
                        <Form.Check>
                          <Form.Check.Input type='radio' id="rush-delivery"
                                            name="deliveryType"
                                            value="Rush Delivery"
                                            checked={formState.deliveryType === "Rush Delivery"}
                                            onChange={handleBookFormChange}/>
                          <Form.Check.Label htmlFor="rush-delivery">Need a rush delivery</Form.Check.Label>
                        </Form.Check>
                      </Col>
                    </Form.Row>

                    <Form.Row className={[booksStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                      <Col xs={12} sm={12} md={12}>
                        <Form.Group as={Col} className={[booksStyle.registerFormGroup, booksStyle.formLabel]}>
                          <Form.Label className={[formStyle.formLabel, formStyle.required]} htmlFor="aboutBook">Tell us about your book</Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.aboutBook}
                            name="aboutBook"
                            type="text"
                            placeholder="Write here..."
                            onChange={handleBookFormChange}
                            className={[booksStyle.formInput, booksStyle.addressInput].join(' ')}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Row>
                      <Col md={6}>
                        {
                          showModalError.show &&
                          <Alert variant="danger" onClose={() => setShowModalErrorAlert({show: false})} dismissible
                          >{showModalError.onField} field should not be empty</Alert>
                        }
                      </Col>
                    </Row>

                    {/*{showAlert &&*/}
                    {/*<Row>*/}
                    {/*  <Col>*/}
                    {/*    <Alert*/}
                    {/*      variant="danger"*/}
                    {/*      onClose={() => setShowAlert({ show: false })}*/}
                    {/*      dismissible*/}
                    {/*    >*/}
                    {/*      {showAlert.msg}*/}
                    {/*    </Alert>*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}
                    {/*}*/}

                    <Row className="justify-content-md-center">
                      <Col className="text-center">
                        <Button
                          type="button"
                          className={[
                            globalStyle.btnPrimary,
                            booksStyle.confirmButton
                          ]}
                          onClick={validateAndSaveData}
                        >Submit
                        </Button>
                      </Col>
                    </Row>

                  </Modal.Body>
                </Modal>
              </Col>
            </Form.Row>

          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    booksCarousel: allFile(filter: {relativePath: {regex: "/books\/.*/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    booksLayout: file(relativePath: { eq: "books-layout/process-example.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    orLine: file(relativePath: { eq: "books-layout/or-line.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default BooksPage
