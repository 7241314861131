import React from "react";
import Img from "gatsby-image";
import {Row, Col} from 'react-bootstrap';

const BooksPortraitCarousel = ({photo}) => {
  return (
    <div>
      <Row>
        <Col style={{"margin": "0px"}}>
          <Img fluid={photo} />
        </Col>
      </Row>
    </div>
  )
};

export default BooksPortraitCarousel


